import Vue from 'vue'
import Vuex from 'vuex'

// 将 vuex 注册为 vue 组件
Vue.use(Vuex)

// 实例化 vuex
const store = new Vuex.Store({
  state: {
    title: '测试平台',
    num: 1,
    user: window.localStorage.getItem('user') || null,
    username: window.localStorage.getItem('username') || null,
    projectId: parseInt(window.localStorage.getItem('projectId')) || null,
    suiteId: parseInt(window.localStorage.getItem('suiteId')) || null,
    reportInfo: window.localStorage.getItem('reportInfo') || null
  },
  mutations: {
    // 当前对象当中存放着嘴中操作状态（数据）的方法
    setUser (state, payload) {
      // 将数据放入到state中
      state.user = payload
      // 为了防止后续身份数据丢失，将token缓存到浏览器中
      window.localStorage.setItem('user', payload)
    },
    saveUser (state, payload) {
      // 将数据存储到state中
      state.username = payload
      window.localStorage.setItem('username', payload)
    },
    saveProjectId (state, payload) {
      state.projectId = parseInt(payload)
      window.localStorage.setItem('projectId', parseInt(payload))
      console.log(payload, '<--传入的项目ID')
    },
    saveSuiteId (state, payload) {
      state.suiteId = parseInt(payload)
      window.localStorage.setItem('suiteId', parseInt(payload))
    },
    saveReportInfo (state, payload) {
      state.reportInfo = payload
      window.localStorage.setItem('reportInfo', payload)
    },
    increment (state, payload) {
      // console.log(state)
      // console.log(payload)
      state.num += payload
    },
    decrement (state, payload) {
      state.num -= payload
    }
  },
  getters: {
    // vuex当中的getters 就类似于组件自己的 computed
    doubleNum (state) {
      // console.log(state)
      return state.num * 2
    }
  },
  actions: {
    waitNum (context, payload) {
      setTimeout(() => {
        context.commit('increment', payload)
      }, 1000)
    }
  }
})

export default store
