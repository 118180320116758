// 路由文件，用来定义路由跳转规则

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    // 引入login组件
    component: () => import(/* webpackChunkName: "login" */'@/views/login/index.vue'),
    meta: { title: '登录' }
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "layout" */'@/views/layout/index.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */'@/views/home/index.vue')
      },
      {
        path: 'project',
        name: 'project',
        component: () => import(/* webpackChunkName: "project" */'@/views/project/index.vue'),
        meta: { title: '项目管理' }
      },
      {
        path: 'module',
        name: 'module',
        component: () => import(/* webpackChunkName: "module" */'@/views/module/index.vue'),
        meta: { title: '模块管理' }
      },
      {
        path: 'cron',
        name: 'cron',
        component: () => import(/* webpackChunkName: "cron" */'@/views/cron/index.vue'),
        meta: { title: '定时任务' }
      },
      {
        path: 'apiReport',
        name: 'apiReport',
        component: () => import(/* webpackChunkName: "apiReport" */'@/views/chart_report/apiReport/index.vue'),
        meta: { title: '接口测试报告' }
      },
      {
        path: 'apiReport/info',
        name: 'reportInfo',
        component: () => import(/* webpackChunkName: "reportInfo" */'@/views/chart_report/apiReport/reportInfo.vue'),
        meta: { title: '报告详情' }
      },
      {
        path: 'webUIReport',
        name: 'webUIReport',
        component: () => import(/* webpackChunkName: "webUIReport" */'@/views/chart_report/webUIReport/index.vue'),
        meta: { title: 'UI测试报告' }
      },
      {
        path: 'webUIReport/info',
        name: 'webUIreportInfo',
        component: () => import(/* webpackChunkName: "WebUIreportInfo" */'@/views/chart_report/webUIReport/reportInfo.vue'),
        meta: { title: '报告详情' }
      },
      {
        path: 'defectMetricAnalysis',
        name: 'defectMetricAnalysis',
        component: () => import(/* webpackChunkName: "defectMetricAnalysis" */'@/views/chart_report/defectMetricAnalysis/index.vue'),
        meta: { title: '缺陷度量分析' }
      },
      {
        path: 'helpTools',
        name: 'helpTools',
        component: () => import(/* webpackChunkName: "helpTools" */'@/views/testTools/helpTools.vue'),
        meta: { title: '常用工具入口' }
      },
      {
        path: 'productionTools',
        name: 'productionTools',
        component: () => import(/* webpackChunkName: "productionTools" */'@/views/testTools/productionTools.vue'),
        meta: { title: '生产工具' }
      },
      {
        path: 'chart/useFrequency',
        name: 'useFrequency',
        component: () => import(/* webpackChunkName: "useFrequency" */'@/views/chart_report/useFrequency.vue'),
        meta: { title: '使用频次' }
      },
      {
        path: 'chart/report',
        name: 'chartReport',
        component: () => import(/* webpackChunkName: "chart_report" */'@/views/chart_report/index.vue'),
        meta: { title: 'chart报告' }
      },
      {
        path: 'suite',
        name: 'suite',
        component: () => import(/* webpackChunkName: "suite" */'@/views/suite/index.vue'),
        children: [
          {
            path: '',
            name: 'caseSuite',
            component: () => import(/* webpackChunkName: "caseSuite" */'@/views/suite/caseSuite.vue'),
            meta: { title: '测试套件' }
          },
          {
            path: 'caseList',
            name: 'caseList',
            component: () => import(/* webpackChunkName: "caseList" */'@/views/suite/caseList.vue'),
            meta: { title: '测试用例' }
          },
          {
            path: 'env',
            name: 'env',
            component: () => import(/* webpackChunkName: "envList" */'@/views/suite/components/env.vue'),
            meta: { title: '环境配置' }
          }
        ]
      },
      {
        path: 'apiBaseInfo',
        name: 'apiBaseInfo',
        component: () => import(/* webpackChunkName: "apiBaseInfo" */'@/views/apiTest/apiBaseInfo.vue'),
        meta: { title: '接口管理' }
      },
      {
        path: 'apiCase',
        name: 'apiCase',
        component: () => import(/* webpackChunkName: "apiCase" */'@/views/apiTest/apiCase.vue'),
        meta: { title: '接口用例' }
      },
      {
        path: 'apiEnv',
        name: 'apiEnv',
        component: () => import(/* webpackChunkName: "apiEnv" */'@/views/apiTest/apiEnv.vue'),
        meta: { title: '环境管理' }
      },
      {
        path: 'apiEnvVar',
        name: 'apiEnvVar',
        component: () => import(/* webpackChunkName: "apiEnvVar" */'@/views/apiTest/apiEnvVar.vue'),
        meta: { title: '环境变量管理' }
      },
      {
        path: 'uiPage',
        name: 'uiPage',
        component: () => import(/* webpackChunkName: "uiPage" */'@/views/webUiTest/uiPage.vue'),
        meta: { title: '页面管理' }
      },
      {
        path: 'uiElement',
        name: 'uiElement',
        component: () => import(/* webpackChunkName: "uiElement" */'@/views/webUiTest/uiElement.vue'),
        meta: { title: '元素管理' }
      },
      {
        path: 'uiStep',
        name: 'uiStep',
        component: () => import(/* webpackChunkName: "uiStep" */'@/views/webUiTest/uiStep.vue'),
        meta: { title: '步骤管理' }
      },
      {
        path: 'uiAction',
        name: 'uiAction',
        component: () => import(/* webpackChunkName: "uiAction" */'@/views/webUiTest/uiAction.vue'),
        meta: { title: '动作管理' }
      },
      {
        path: 'uiCase',
        name: 'uiCase',
        component: () => import(/* webpackChunkName: "uiCase" */'@/views/webUiTest/uiCase.vue'),
        meta: { title: '用例管理' }
      },
      {
        path: 'uiEnv',
        name: 'uiEnv',
        component: () => import(/* webpackChunkName: "uiEnv" */'@/views/webUiTest/uiEnv.vue'),
        meta: { title: '环境管理' }
      },
      {
        path: 'system/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "account" */'@/views/system/account.vue'),
        meta: { title: '账户管理' }
      },
      {
        path: 'system/role',
        name: 'role',
        component: () => import(/* webpackChunkName: "role" */'@/views/system/role.vue'),
        meta: { title: '角色管理' }
      },
      {
        path: 'system/menu',
        name: 'menu',
        component: () => import(/* webpackChunkName: "menu" */'@/views/system/menu.vue'),
        meta: { title: '菜单管理' }
      }
    ]
  },
  {
    path: '*',
    name: 'errorPage',
    component: () => import(/* webpackChunkName: "errorPage" */'@/views/errorPage/index.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '测试平台'; // 根据路由元信息设置标题
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // if (!auth.loggedIn()) {
    //   next({
    //     path: '/login',
    //     query: { redirect: to.fullPath }
    //   })
    // } else {
    //   next()
    // }
    console.log('需要登录', '<--该页面是否需要登录访问')
    if (!store.state.user) {
      return next({
        name: 'login',
        // 将本次路由的 fullPath 保存，登录成功后跳转
        query: { redirect: to.fullPath }
      })
    }
    next()
  } else {
    console.log('不需要登录', '<--该页面是否需要登录访问')
    next() // 确保一定要调用 next()
  }
})

export default router
