import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/global.css'

// 导入 iconfont 图标样式
import '@/assets/font/iconfont.css'

// 引入 element 核心文件
import ElementUI from 'element-ui'
// 引入element自己组件的CSS样式 （主题）
import 'element-ui/lib/theme-chalk/index.css'

// 引入 自定义的样式
import './styles/default.scss'
// import './styles/index.scss'

import VueMonaco from 'vue-monaco'
Vue.component('vue-monaco', VueMonaco)

// // 处理 axios 模块
// import axios from 'axios'

// // 使用 axios 请求时默认的域名
// axios.defaults.baseURL = '/proxy_url'

// // 使用axios发送请求 默认为 this.$http
// Vue.prototype.$http = axios

// 将element 注册为 Vue 的插件使用
Vue.use(ElementUI, { size: 'small' })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
