<template>
  <div id="app">
    <!-- <Login></Login> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import Login from '@/views/login/index.vue'
export default {
  // name: 'App',
  // components: { Login }
}
</script>

<style lang="scss">
// /* 如果想使用其他的样式，那么就需要导入 */
// // @import '~@/style/variables.scss';
//   button{
//     color: orange;
//     font: normal 14px/40px "微软雅黑";
//     background: orangered;
//   }
//   div {
//     background-color: $warning-color;
//   }
</style>
